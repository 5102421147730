import { useHistory, useParams } from 'react-router-dom';
import React, {useState} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { useMiracleObserver, useMiracleObserverHot, createNotifyStation, SubscribeMatchType, useMiracleStore, getStore } from 'miracle-observer';
import appServices from './services';

function Home() {

  let history = useHistory();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [homePaper, setHomePaper] = useState(() => {
    return getStore('homePaper')
  });

  //证书展示
  const [certlist, setCertlist] = useState([]);

  //合作伙伴
  const [parlist, setParlist] = useState([]);

  //新闻中心
  const [newsList, setNewsList] = useState([]);


  //产品展示
  const [allProClass, setAllProClass] = useState([]);
  const [showProList, setShowProList] = useState([]);

  //产品分类
  const [nowProClass, setNowProClass, initNowProClass] = useMiracleStore(

    (store) => {
      return store['nowProClass'];
    },
    (v, store) => {
      store['nowProClass'] = v;
      appServices.getProClassList();
      return v;
    });


  useMiracleObserver(
      'getHomePaper.getCertlist.getParlist.getNewsList.getAllProClass.getShowProList',
      (key, value, store) => {

        if(key === 'getHomePaper'){
          setTimeout(() => {
            window.staticpage.upload(getStore('pageUrl'), value.content_info.searchTitle, value.content_info.searchDesc, value.content_info.keywords);
          },500);
          setHomePaper(value);
        }
        if(key === 'getCertlist'){
          setCertlist(value);
        }
        if(key === 'getParlist'){
          setParlist(value);
        }
        if(key === 'getNewsList'){
          setNewsList(value);
        }
        if(key === 'getAllProClass'){
          setAllProClass(value);
        }
        if(key === 'getShowProList'){
          setShowProList(value);
        }
      },
      SubscribeMatchType.ContainsNotify,
  );

  return (
    <div>
      <div style={{ overflow: 'hidden', position: 'relative', lineHeight: 0, pointerEvents: 'none' }}>
        <div className="swap" style={{ left: photoIndex === 0 ? 0 : `-${photoIndex}00%`, zIndex: -1 }}>
          {
              homePaper['base_info'].photoList?
                homePaper['base_info'].photoList.map((item,index) => (
                <img key={`homeBasePhoto${item}`} src={item} alt="" style={{ opacity: photoIndex === index ? 1 : 0, transitionDuration: photoIndex === index ?'0.5s' : '1.5s', width: '100%' }} />
              ))
                  :
                  null
          }
        </div>

        <div className="swapButton" style={{ pointerEvents: 'all', display: homePaper['base_info'].photoList && homePaper['base_info'].photoList.length > 1 ? '' : 'none' }}>
          {
            homePaper['base_info'].photoList?
              homePaper['base_info'].photoList.map((item,index) => (
              <div
                onClick={() => {
                  setPhotoIndex(index);
                }}
                style={{ background: photoIndex === index ? '#C90000' : '#FFFFFF' }}
              />
            ))
                :
                null
          }
        </div>
      </div>
      <div className="webSee homeContact">
        <div className="homeContactBlock">
          <img src="/homePhone.png"  alt="" style={{ height: 76, marginRight: 24 }} />
          <div>
            <div>{homePaper['content_info']?homePaper['content_info'].leftFont6:''}</div>
            <div>{homePaper['content_info']?homePaper['content_info'].telPhone:''}</div>
          </div>
        </div>
        <div style={{ width: 2, height: 68, background: '#fff', margin: '0 32px' }} />
        <div className="homeContactBlock">
          <img src="/homeAddress.png"  alt="" style={{ height: 76, marginRight: 24 }} />
          <div>
            <div>{homePaper['content_info']?homePaper['content_info'].rightFont6:''}</div>
            <div>{homePaper['content_info']?homePaper['content_info'].address:''}</div>
          </div>
        </div>
      </div>
      <div className="homeAboutPapar">
        <div className="aboutTitle">
          <img src="/titleLeft.png" alt="" />
          <div><span style={{ color: homePaper['content_info']?homePaper['content_info'].leftColor1:'' }}>{homePaper['content_info']?homePaper['content_info'].leftFont1:''}</span><span style={{ marginLeft: 2, color: homePaper['content_info']?homePaper['content_info'].rightColor1:'' }}>{homePaper['content_info']?homePaper['content_info'].rightFont1:''}</span></div>
          <img src="/titleRight.png" alt="" />
        </div>
        <div style={{ display: 'flex', letterSpacing: '0.5px' }}>
          <div style={{ flex: 1, padding: 24, background: '#323232', color: '#CACACA' }}>
            <div className="homeAboutTitle">{homePaper['content_info']?homePaper['content_info'].name:''}</div>
            <pre className="homeAboutPre">{homePaper['content_info']?homePaper['content_info'].desc:''}</pre>
            <div>
              <div className="homeAbout">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontSize: 40, marginRight: 8, color: '#C90000', fontWeight: 500 }}>{homePaper['base_info'].developers}</div>
                  <div>
                    <div className="webSee" style={{ fontSize: 12, color: '#ffffff' }}>DEVELOPERS</div>
                    <img style={{ width: 10 }} alt="" src="/homeAdd.png" />
                  </div>
                </div>
                <div>研发团队{homePaper['base_info'].developers}人</div>
              </div>
              <div className="homeAbout">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontSize: 40, marginRight: 8, color: '#C90000', fontWeight: 500 }}>{homePaper['base_info'].squareMetre}</div>
                  <div>
                    <div className="webSee" style={{ fontSize: 12, color: '#ffffff' }}>SQUARE  METRE</div>
                    <img style={{ width: 10 }} alt="" src="/homeAdd.png" />
                  </div>
                </div>
                <div>企业占地面积{homePaper['base_info'].squareMetre}m²</div>
              </div>
              <div className="homeAbout">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontSize: 40, marginRight: 8, color: '#C90000', fontWeight: 500 }}>{homePaper['base_info'].equipment}</div>
                  <div>
                    <div className="webSee" style={{ fontSize: 12, color: '#ffffff' }}>EQUIPMENT</div>
                    <img style={{ width: 10 }} alt="" src="/homeAdd.png" />
                  </div>
                </div>
                <div>各类仪器设备{homePaper['base_info'].equipment}余台</div>
              </div>
              <div className="homeAbout">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontSize: 40, marginRight: 8, color: '#C90000', fontWeight: 500 }}>{homePaper['base_info'].production}</div>
                  <div>
                    <div className="webSee" style={{ fontSize: 12, color: '#ffffff' }}>PRODUCTION</div>
                    <img style={{ width: 10 }} alt="" src="/homeAdd.png" />
                  </div>
                </div>
                <div>月产量{homePaper['base_info'].production}多吨</div>
              </div>
            </div>
            <img className="mobileSee" src={homePaper['base_info'].picture} alt="" style={{ width: '100%', marginTop: 20 }} />
          </div>
          <img className="webSee" src={homePaper['base_info'].picture} alt="" style={{ width: '25%', marginLeft: 18 }} />
        </div>
      </div>
      <div className="content">
        <div className="aboutTitle">
          <img src="/titleLeft.png" alt="" />
          <div><span style={{ color: homePaper['content_info']?homePaper['content_info'].leftColor2:'' }}>{homePaper['content_info']?homePaper['content_info'].leftFont2:''}</span><span style={{ marginLeft: 2, color: homePaper['content_info']?homePaper['content_info'].rightColor2:'' }}>{homePaper['content_info']?homePaper['content_info'].rightFont2:''}</span></div>
          <img src="/titleRight.png" alt="" />
        </div>
        <div>

          <div  style={{ display:'flex',fontSize:14,color:'#fff' , }}>
            <div className="webSee" style={{ width:'26%', marginRight:'4%', backgroundColor:'#000000', display:'flex', flexWrap:'wrap', padding:'40px 0 ' }}>
              <div style={{ paddingLeft:'30px' }}>

                <div style={{ fontSize:20,  marginBottom:30}}>
                  <div>
                    产品分类
                  </div>
                  <div>
                    PRODUCT CENTER
                  </div>
                </div>
                {
                  allProClass.map((item, index) => (
                      index<6?
                      <div
                        style={{  color:'#fff', margin:'14px 0', cursor:'pointer' }}
                        onClick={() => {
                          setNowProClass(item.base_tag)
                          history.push('/product'+ getStore('pageSearch'));
                        }}
                      >

                        { item.content_name }
                      </div>
                          :
                          null
                  ))
                }

                <div style={{ marginTop:'30px', fontSize:16, cursor:'pointer'  }}
                     onClick={() => {
                        history.push('/product'+ getStore('pageSearch'));
                      }}
                >
                  <div>
                    更多产品
                  </div>
                  <div>
                    MORE
                  </div>
                </div>
              </div>
            </div>


            <div  style={{ display:'flex', flexWrap:'wrap', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
              {
                showProList.map((item, index) => (
                  <div
                    className="homeCoop"
                    onClick={() => {
                      setNowProClass(item.base_tag);
                      history.push('/product'+ getStore('pageSearch'));
                    }}
                  >
                    {
                      item.base_info&&item.base_info.photoList?
                        <div className="imgBlock">
                          <img src={ item.base_info.photoList[0] } alt="" />
                        </div>
                        :
                        null
                    }
                    <div style={{ paddingTop:'10px', textAlign:'center', fontSize:18, color:'#999999' }} >
                      { item.content_name }
                    </div>
                  </div>
                ))
              }

            </div>

          </div>
        </div>

        <div className="aboutTitle">
          <img src="/titleLeft.png" alt="" />
          <div><span style={{ color: homePaper['content_info']?homePaper['content_info'].leftColor3:'' }}>{homePaper['content_info']?homePaper['content_info'].leftFont3:''}</span><span style={{ marginLeft: 2, color: homePaper['content_info']?homePaper['content_info'].rightColor3:'' }}>{homePaper['content_info']?homePaper['content_info'].rightFont3:''}</span></div>
          <img src="/titleRight.png" alt="" />
        </div>
        <div style={{display:'flex' }}>

          <img className="webSee homeNewPic" src={homePaper['base_info'].picture2} alt="" style={{ marginRight: 18 }} />

          <div style={{ flex: 1 }}>
            {
              newsList.map((item, index) => (
              index<3?
                  <Link
                    to={'/newdetail/' + item.com_base_id + getStore('pageSearch')}
                    style={{ cursor:'pointer', color: 'inherit', textDecoration: 'none' }}
                    onClick={() => {
                  }}>

                    <div style={{ paddingTop:index===0?'':16 }}>
                      <div className="mobileFlex" style={{ display:'flex', marginBottom:13 }}>
                        <div className="mobileNewsList" style={{ color:'#333333',fontWeight:600 }}>
                          {item.content_name}
                        </div>
                        {index === 0 ?
                          <img className="mobileSee mobileNewsList" src={ item.base_info&&item.base_info.pressPic?item.base_info.pressPic:'' } alt="" style={{ width:'100%' }}/>
                          : null
                        }
                        <div style={{  fontSize:14 }}>
                          {
                            item.content_info&&item.content_info.postTime?
                                <div style={{ display:'flex', alignItems:'center' }}>
                                  <img src="/img/timer.png" alt="" style={{ width:12, marginRight:5 }}/>
                                  {item.content_info.postTime.split('T')[0]}
                                </div>
                                :
                                null

                          }
                        </div>

                      </div>


                      <div className="webSee" style={{ paddingBottom:20, fontSize: 14 }}>
                        {
                          item.content_info&&item.content_info.brief?
                              <div className="homeNewLine">
                                { item.content_info.brief.length>250?item.content_info.brief.substring(0,250) + "...":item.content_info.brief}
                              </div>
                              :
                              null
                        }
                      </div>
                      <div style={{ width: '100%', height: 1, background: '#DEDEDE' }} />

                    </div>
                  </Link>
                  :
                  null
              ))
            }

          </div>
        </div>

        <div className="webSee aboutTitle">
          <img src="/titleLeft.png" alt="" />
          <div><span style={{ color: homePaper['content_info']?homePaper['content_info'].leftColor4:'' }}>{homePaper['content_info']?homePaper['content_info'].leftFont4:''}</span><span style={{ marginLeft: 2, color: homePaper['content_info']?homePaper['content_info'].rightColor4:'' }}>{homePaper['content_info']?homePaper['content_info'].rightFont4:''}</span></div>
          <img src="/titleRight.png" alt="" />
        </div>
        <div className="webSee" style={{ padding: 24, textAlign: 'center' }}>
          {
            certlist.map((item) =>(
                <img src={ item.base_rtf } alt="" style={{ margin:'0 20px 20px 20px', height:150 }}/>
            ))
          }
        </div>

        <div id="HomeCoop" className="aboutTitle">
          <img src="/titleLeft.png" alt="" />
          <div><span style={{ color: homePaper['content_info']?homePaper['content_info'].leftColor5:'' }}>{homePaper['content_info']?homePaper['content_info'].leftFont5:''}</span><span style={{ marginLeft: 2, color: homePaper['content_info']?homePaper['content_info'].rightColor5:'' }}>{homePaper['content_info']?homePaper['content_info'].rightFont5:''}</span></div>
          <img src="/titleRight.png" alt="" />
        </div>
        <div style={{ padding: 24, textAlign: 'center' }}>

          {
            parlist.map((item) =>( item.base_tag ?
                <a style={{ color: 'inherit', textDecoration: 'none' }} href={item.base_tag} alt="" target="_blank"><img className="homeListImg" src={ item.base_rtf } alt="" style={{ margin:'0 20px 20px 20px' }}/></a>
                :
                <img className="homeListImg" src={ item.base_rtf } alt="" style={{ margin:'0 20px 20px 20px' }}/>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default Home;