import React, {useState} from 'react';
import { useMiracleObserver, getStore, createNotifyStation, SubscribeMatchType } from 'miracle-observer';
import './App.css';

function About(props) {
    const [aboutPaper, setAboutPaper] = useState(() => {
      return getStore('aboutPaper')
    });

    useMiracleObserver(
        'getAboutPaper',
        (key, value, store) => {

            if(key === 'getAboutPaper'){
                setAboutPaper(value);
                setTimeout(() => {
                  window.staticpage.upload(getStore('pageUrl'), value.content_info.searchTitle, value.content_info.searchDesc, value.content_info.keywords);
                },500);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

  return (
    <div className="content">
      <div className="webSee aboutTitle">
        <img src="/titleLeft.png" style={{ height: 20 }} alt="" />
        <div><span style={{ color: aboutPaper['content_info']?aboutPaper['content_info'].leftColor1:'' }}>{aboutPaper['content_info']?aboutPaper['content_info'].leftFont1:''}</span><span style={{ marginLeft: 2, color: aboutPaper['content_info']?aboutPaper['content_info'].rightColor1:'' }}>{aboutPaper['content_info']?aboutPaper['content_info'].rightFont1:''}</span></div>
        <img src="/titleRight.png" style={{ height: 20 }} alt="" />
      </div>
      <div className="aboutTitlePre" dangerouslySetInnerHTML={{ __html: aboutPaper['content_rtf'] }} />
      {aboutPaper['base_info'].photoList ?
        <div className="aboutPhoto">
          {aboutPaper['base_info'].photoList.map(item => (
            <img alt="" src={item} />
          ))}
        </div>
        :
        null
      }
    </div>
  );
}

export default About;