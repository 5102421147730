import React, {useState, useEffect} from 'react';
import {SubscribeMatchType, useMiracleObserver, getStore} from 'miracle-observer';
import { useHistory, useParams } from 'react-router-dom';
import services from "./services";

function ComTwo(props) {
  let history = useHistory();
  let { id } = useParams();

  const [dict, setDict] = useState(() => {
    return getStore('about2');
  });

  useEffect(() => {
    // init call
    console.log('useEffect:' + id);
    console.log(history.action);
    services.fetchDict();
  }, [id]);

  useMiracleObserver(
    'updateDict',
    (key, value, store) => {
      setDict(JSON.stringify(value));
    },
    SubscribeMatchType.ContainsNotify,
  );

  return (
    <div className="App" onClick={() => history.push('/com_one')}>
      <p>{id}</p>
      <div dangerouslySetInnerHTML={{ __html: dict }} />
    </div>
  );
}

export default ComTwo;