import React, {useState} from 'react';
import { useMiracleObserver, getStore, createNotifyStation, SubscribeMatchType } from 'miracle-observer';
import './App.css';

function Contact(props) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [contactBase, setContactBase] = useState(() => {
    return getStore('contactBase')
  });
  useMiracleObserver(
    'getContactBase',
    (key, value, store) => {
      if(key === 'getContactBase'){
        setTimeout(() => {
          window.staticpage.upload(getStore('pageUrl'), value.content_info.searchTitle, value.content_info.searchDesc, value.content_info.keywords);
        },500);
        setContactBase(value);
      }
    },
    SubscribeMatchType.ContainsNotify,
  );

  return (
    <div>
      <div style={{ overflow: 'hidden', position: 'relative', lineHeight: 0, pointerEvents: 'none' }}>
        <div className="swap" style={{ left: photoIndex === 0 ? 0 : `-${photoIndex}00%`, zIndex: -1 }}>
          {
            contactBase['base_info'].photoList?
              contactBase['base_info'].photoList.map((item,index) => (
                <img key={`contactBasePhoto${item}`} src={item} alt="" style={{ opacity: photoIndex === index ? 1 : 0, transitionDuration: photoIndex === index ?'0.5s' : '1.5s', width: '100%' }} />
              ))
              :
              null
          }
        </div>

        <div className="swapButton" style={{ pointerEvents: 'all', display: contactBase['base_info'].photoList && contactBase['base_info'].photoList.length > 1 ? '' : 'none' }}>
          {
            contactBase['base_info'].photoList?
              contactBase['base_info'].photoList.map((item,index) => (
                <div
                  onClick={() => {
                    setPhotoIndex(index);
                  }}
                  style={{ background: photoIndex === index ? '#C90000' : '#FFFFFF' }}
                />
              ))
              :
              null
          }
        </div>
      </div>

      <div className="content">
        <div className="webSee aboutTitle">
          <img src="/titleLeft.png" style={{ height: 20 }} alt="" />
          <div><span style={{ color: contactBase['content_info'].leftColor1 }}>{contactBase['content_info'].leftFont1}</span><span style={{ marginLeft: 2, color: contactBase['content_info'].rightColor1 }}>{contactBase['content_info'].rightFont1}</span></div>
          <img src="/titleRight.png" style={{ height: 20 }} alt="" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 24 }}>
          <div className="coopContent" style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: contactBase['content_rtf'] }} />
          <img src={contactBase['base_info'].picture} alt="" style={{ width: '40%', marginLeft: 32 }} />
        </div>

      </div>
    </div>
  );
}

export default Contact;