/*
返回 Promise 类型调用，方便复杂项目时组合封装并行接口调用的逻辑，支持actions，多models
 */

import axios from 'axios';
import {getStore, initStore} from 'miracle-observer';
import stations from './stations';

const { stationDefault } = stations;

export default {
  fetchDict: () => {
    return axios.get('/api/dict').then((response) => {
      stationDefault.notify('updateDict', response.data);
    }).catch((error) => {
      console.log(error.toJSON());
    });
  },
  fetchAboutContent: () => {
    const url = '/api/web_content?com_base_id=eq.6&i18n_id=eq.' + getStore('i18n_id');
    return axios.get(url).then((response) => {
      const data = response.data[0] || '';
      initStore(data, 'about1');    // 可以操作 store
      stationDefault.notify('updateAboutContent', data);
    }).catch((error) => {
      console.log(error.toJSON());
    });
  },

}
