/*
返回 Promise 类型调用，方便复杂项目时组合封装并行接口调用的逻辑，支持actions，多models
 */

import axios from 'axios';
import {getStore, initStore} from 'miracle-observer';
import stations from './stations';

const { stationDefault } = stations;

export default {
  getAllStyle: () => {
    const url = `/api/web_categorys?category_id=4&in_id=` + getStore('i18n_id') + `&order=base_rank.desc`;
    return axios.get(url).then((response) => {
      const list = response.data;
      const bottomTab = [];
      const navList = [];
      for (let i = 0; i < list.length; i += 1) {
        if (list[i]['com_base_id'] !== 34) {
          const name = list[i].base_name;
          navList.push({
            name: list[i].content_name,
            link: name === '首页' ? '/' : name === '关于我们' ? '/about' : name === '产品展示' ? '/product' : name === '新闻中心' ? '/news' : name === '战略合作' ? '/cooperation' : '/contact'
          });
        }
        if (list[i]['com_base_id'] === 29) {
          initStore(list[i], 'coopBase');
          stationDefault.notify('getCoopBase', list[i]);
        } else if (list[i]['com_base_id'] === 30) {
          initStore(list[i], 'contactBase');
          stationDefault.notify('getContactBase', list[i]);
        } else if (list[i]['com_base_id'] === 27) {
          initStore(list[i], 'proPaper');
          stationDefault.notify('getProPaper', list[i]);
        } else if (list[i]['com_base_id'] === 28) {
          initStore(list[i], 'newsPaper');
          stationDefault.notify('getNewsPaper', list[i]);
        } else if (list[i]['com_base_id'] === 31) {
          initStore(list[i], 'homePaper');
          stationDefault.notify('getHomePaper', list[i]);
        } else if (list[i]['com_base_id'] === 6) {
          initStore(list[i], 'aboutPaper');
          stationDefault.notify('getAboutPaper', list[i]);
        } else if (list[i]['com_base_id'] === 34) {
          bottomTab.push(list[i]);
        }
      }
      initStore(navList, 'navList');
      stationDefault.notify('setNavList', navList);
      console.log(navList);
      stationDefault.notify('getBottomTab', bottomTab);
    }).catch((error) => {
      console.log(error.toJSON());
    });
  },

  getCoopBase: () => {
    const url = `/api/web_coms?com_id=29&in_id=` + getStore('i18n_id');
    return axios.get(url).then((response) => {
      const data = response.data[0] || {};
      initStore(data, 'coopBase');
      stationDefault.notify('getCoopBase', data);
    }).catch((error) => {
      console.log(error.toJSON());
    });
  },

  getContactBase: () => {
    const url = `/api/web_coms?com_id=30&in_id=` + getStore('i18n_id');
    return axios.get(url).then((response) => {
      const data = response.data[0] || {};
      initStore(data, 'contactBase');
      stationDefault.notify('getContactBase', data);
    }).catch((error) => {
      console.log(error.toJSON());
    });
  },

    //首页样式
    getHomePaper: () => {
        const url = `/api/web_coms?com_id=31&in_id=` + getStore('i18n_id');
        return axios.get(url).then((response) => {
            const data = response.data[0]|| {};
            initStore(data, 'homePaper');
            stationDefault.notify('getHomePaper', data);
        }).catch((error) => {
            console.log(error.toJSON());
        });
    },

    //证书
    getCertlist: () => {
        const url = '/api/web_base?com_category_id=eq.3'
        //const url = '/api/web_content?com_base_id=eq.71';
        return axios.get(url).then((response) => {
            const data = response.data || [];
            initStore(data, 'certlist');
            console.log('certlist',data)
            stationDefault.notify('getCertlist', data);

        }).catch((error) => {
            console.log(error.toJSON());
        });
    },

    //合作伙伴
    getParlist: () => {
        const url = '/api/web_base?com_category_id=eq.2'
        //const url = '/api/web_content?com_base_id=eq.71';
        return axios.get(url).then((response) => {
            const data = response.data || [];
            initStore(data, 'parlist');
            stationDefault.notify('getParlist', data);

        }).catch((error) => {
            console.log(error.toJSON());
        });
    },


    //关于我们
    getAboutPaper: () => {
        //const url = '/api/web_categorys?category_id=6&in_id=' + getStore('i18n_id');
        const url = `/api/web_coms?com_id=6&in_id=` + getStore('i18n_id');
        //const url = `/api/web_coms?com_id=${id}&in_id=` + getStore('i18n_id');
        //const url = '/api/web_content?com_base_id=eq.71';
        return axios.get(url).then((response) => {
            const data = response.data[0] || {};
            initStore(data, 'aboutPaper');
            stationDefault.notify('getAboutPaper', data);
        }).catch((error) => {
            console.log(error.toJSON());
        });
    },

    //产品
    getProPaper: () => {
        const url = `/api/web_coms?com_id=27&in_id=` + getStore('i18n_id');
        return axios.get(url).then((response) => {
            const data = response.data[0] || {};
            initStore(data, 'proPaper');
            stationDefault.notify('getProPaper', data);
        }).catch((error) => {
            console.log(error.toJSON());
        });
    },
    //所有产品分类
    getAllProClass: () => {
        const url = '/api/web_categorys?category_id=1&in_id=' + getStore('i18n_id')+`&order=base_rank.desc`

        return axios.get(url).then((response) => {
            const data = response.data || [];
            initStore(data, 'proClass');
            console.log('proClass',data)
            stationDefault.notify('getAllProClass', data);

            let nowProClass = getStore('nowProClass')
            console.log('nowProClassbbbbb--->',nowProClass)
            data.map((item) => {
              if(item.base_tag === nowProClass){
                console.log('匹配的base_tag',item.base_tag)
                initStore(item.content_name, 'nowProClassCont')
                initStore(item.base_tag, 'nowProClass')
                stationDefault.notify('nowProClass', item.base_tag);
                stationDefault.notify('nowProClassCont', item.content_name);
                return
              }

            })



        }).catch((error) => {
            console.log(error.toJSON());
        });
    },

    //某类的所有产品
    getProClassList: () => {
        //const url = '/api/web_coms?com_id='+getStore('nowProClass')+'&in_id=' + getStore('i18n_id');
        //const url = '/api/web_coms?com_id='+getStore('nowProClass')+'&in_id=' + getStore('i18n_id');
        console.log('nowProClass---->',getStore('nowProClass'));
        const nowProClass = JSON.parse(JSON.stringify(getStore('nowProClass')));
        console.log('nowProClassaaa---->',nowProClass);
        const url = `/api/web_categorys?category_id=6&in_id=` + getStore('i18n_id')+`&base_tag=eq.`+nowProClass;
        return axios.get(url).then((response) => {
            const data = response.data || [];
            initStore(data, 'proClassList');
            stationDefault.notify('getProClassList', data);

        }).catch((error) => {
            console.log(error.toJSON());
        });
    },


  //首页产品6个
  getShowProList: () => {
    //const url = '/api/web_coms?com_id='+getStore('nowProClass')+'&in_id=' + getStore('i18n_id');
    //const url = '/api/web_coms?com_id='+getStore('nowProClass')+'&in_id=' + getStore('i18n_id');
    const url = `/api/web_categorys?category_id=6&in_id=` + getStore('i18n_id')+`&order=base_rank.desc&limit=6`
    return axios.get(url).then((response) => {
      const data = response.data || [];
      initStore(data, 'showProList');
      stationDefault.notify('getShowProList', data);

    }).catch((error) => {
      console.log(error.toJSON());
    });
  },

    //初始化新闻页码
    initNewsNumber: () =>{
        initStore(1, 'newsNumberPage');
        stationDefault.notify('newsNumberPage', 1);
    },

    //新闻
    getNewsPaper: () => {
        const url = `/api/web_coms?com_id=28&in_id=` + getStore('i18n_id');
        return axios.get(url).then((response) => {
            const data = response.data[0] || {};
            initStore(data, 'newsPaper');
            stationDefault.notify('getNewsPaper', data);
        }).catch((error) => {
            console.log(error.toJSON());
        });
    },

    //获取新闻列表
    getNewsList: () => {
        const url = '/api/web_categorys?category_id=7&in_id=' + getStore('i18n_id');
        //const url = '/api/web_content?com_base_id=eq.71';
        return axios.get(url).then((response) => {
            const olddata = response.data || [];
            let data = []
            olddata.map(item =>{
                if(item.content_info){
                    data.push(item)
                }
            })
            initStore(data, 'newList');
            stationDefault.notify('getNewsList', data);

            //const corrNewslist = data.slice(0,2) || [];
            //let oldcorrNewslist = []
            //data.map((item) =>{
            //    if(item.content_info){
            //        oldcorrNewslist.push(item)
            //    }
            //})
            //let corrNewslist = oldcorrNewslist.slice(0,2) || [];
            //initStore(corrNewslist, 'corrNewslist');
            //console.log('corrNewslist',corrNewslist)
            //stationDefault.notify('getCorrNewslist', corrNewslist);


            //总页码
            initStore(data.length, 'newsNumberPage');
            console.log('总页码',data.length)
            stationDefault.notify('getNewsNumberPage', data.length%3===0?data.length/3:parseInt(data.length/3)+1);
        }).catch((error) => {
            console.log(error.toJSON());
        });
    },

    getCorrNewslist: (id) => {
      const url = '/api/web_categorys?category_id=7&in_id=' + getStore('i18n_id');
      //const url = '/api/web_content?com_base_id=eq.71';
      return axios.get(url).then((response) => {
        console.log('新闻的id',id)
        const olddata = response.data || [];
        let data = []
        olddata.map(item =>{
          if(item.content_info){
            data.push(item)
          }
        })
        initStore(data, 'newList');
        stationDefault.notify('getNewsList', data);

        //const corrNewslist = data.slice(0,2) || [];
        let oldcorrNewslist = []
        data.map((item) =>{
          if(item.content_info&&item.com_base_id!=id){
            oldcorrNewslist.push(item)
          }
        })
        console.log('oldcorrNewslist',oldcorrNewslist)
        let corrNewslist = oldcorrNewslist.slice(0,2) || [];
        initStore(corrNewslist, 'corrNewslist');
        console.log('corrNewslist',corrNewslist)
        stationDefault.notify('getCorrNewslist', corrNewslist);

      }).catch((error) => {
        console.log(error.toJSON());
      });
    },

    //获取新闻栏目图片
    getNewsPic: () => {
        const url = '/api/web_base?com_base_id=eq.28';
        //const url = '/api/web_content?com_base_id=eq.71';
        return axios.get(url).then((response) => {
            const data = response.data[0] || {};
            initStore(data, 'newsPic');
            stationDefault.notify('getNewsPic', data);
        }).catch((error) => {
            console.log(error.toJSON());
        });
    },

    //获取新闻详情
    getNewsDetail: (id) => {
        const url = `/api/web_coms?com_id=${id}&in_id=` + getStore('i18n_id');
        //const url = '/api/web_content?com_base_id=eq.71';
        return axios.get(url).then((response) => {
            const data = response.data[0] || {};
            initStore(data, 'newDetail');
            stationDefault.notify('getNewDetail', data);
        }).catch((error) => {
            console.log(error.toJSON());
        });
    },


  //招聘
    getPosionList: () => {
        const url = '/api/web_categorys?category_id=5&in_id=' + getStore('i18n_id');

        return axios.get(url).then((response) => {
            const data = response.data || [];
            initStore(data, 'posionList');
            stationDefault.notify('getPosionList', data);

        }).catch((error) => {
            console.log(error.toJSON());
        });
    },
}
