import React, {useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import {SubscribeMatchType, useMiracleObserver, getStore, useMiracleStore} from 'miracle-observer';
import './App.css';


function News(props) {
    let history = useHistory();
  const [photoIndex, setPhotoIndex] = useState(0);
    //
    const [newsPaper, setNewsPaper] = useState(() => {
      return getStore('newsPaper')
    });

    //新闻列表
    const [newsList, setNewsList] = useState([]);


    //新闻抬头图片
    const [newsPic, setNewsPic] = useState({ base_info: {} });

    //新闻总页码
    const [newsNumberPage, setNewsNumberPage] = useState(1);
    //当前页码
    //const [nowPage, setNowPage] = useState(1);
    const [nowPage, setNowPage, initNowPage] = useMiracleStore(
        (store) => {
            return store['nowPage'];
        },
        (v, store) => {
            store['nowPage'] = v;
            return v;
        });



    //观察者
    useMiracleObserver(
        'getNewsList.getNewsPic.getNewsPaper.getNewsNumberPage',
        (key, value, store) => {
            if(key === 'getNewsList'){
                setNewsList(value);
            }
            if(key === 'getNewsNumberPage'){//总页数
                setNewsNumberPage(value);
            }
            if(key === 'getNewsPic'){
                setNewsPic(value);
            }
            if(key === 'getNewsPaper'){
              setTimeout(() => {
                window.staticpage.upload(getStore('pageUrl'), value.content_info.searchTitle, value.content_info.searchDesc, value.content_info.keywords);
              },500);
                setNewsPaper(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );


    return (
    <div>

        <div className="webSee">
          <div style={{ overflow: 'hidden', position: 'relative', lineHeight: 0, pointerEvents: 'none' }}>
            <div className="swap" style={{ left: photoIndex === 0 ? 0 : `-${photoIndex}00%`, zIndex: -1 }}>
              {
                newsPic['base_info'].photoList?
                  newsPic['base_info'].photoList.map((item,index) => (
                    <img key={`newsPicPhoto${item}`} src={item} alt="" style={{ opacity: photoIndex === index ? 1 : 0, transitionDuration: photoIndex === index ?'0.5s' : '1.5s', width: '100%' }} />
                  ))
                  :
                  null
              }
            </div>

            <div className="swapButton" style={{ pointerEvents: 'all', display: newsPic['base_info'].photoList && newsPic['base_info'].photoList.length > 1 ? '' : 'none' }}>
              {
                newsPic['base_info'].photoList?
                  newsPic['base_info'].photoList.map((item,index) => (
                    <div
                      onClick={() => {
                        setPhotoIndex(index);
                      }}
                      style={{ background: photoIndex === index ? '#C90000' : '#FFFFFF' }}
                    />
                  ))
                  :
                  null
              }
            </div>
          </div>
        </div>

        <div className="content">

            <div className="webSee aboutTitle">
                <img src="/titleLeft.png" style={{ height: 20 }} alt="" />
                <div><span style={{ color: newsPaper['content_info']?newsPaper['content_info'].leftColor1:'' }}>{newsPaper['content_info']?newsPaper['content_info'].leftFont1:''}</span><span style={{ marginLeft: 2, color: newsPaper['content_info']?newsPaper['content_info'].rightColor1:'' }}>{newsPaper['content_info']?newsPaper['content_info'].rightFont1:''}</span></div>
                <img src="/titleRight.png" style={{ height: 20 }} alt="" />
            </div>



            {
                newsList.map((item, index) => (
                    item.content_info?
                        index>=(nowPage-1)*3&&index<nowPage*3?
                            index%3===0?
                                <Link
                                  to={'/newdetail/' + item.com_base_id + getStore('pageSearch')}
                                  style={{ cursor:'pointer', color: 'inherit', textDecoration: 'none' }}
                                  className="webSee newListFirst"
                                >
                                    <div style={{ flex:'1' }}>
                                        <div>
                                            {
                                                item.content_info.postTime?
                                                    <div style={{ fontSize:30, color:'#C90000' }}>
                                                        {item.content_info.postTime.split('T')[0]}
                                                    </div>
                                                    :
                                                    null

                                            }

                                            <div style={{ margin:'7px 0 5px 0', fontSize:22, fontWeight:600, color:'#333333' }}>
                                              <div style={{ fontSize:22 }}>
                                                {item.content_name}
                                              </div>
                                            </div>

                                            <div className="newFirstLine">
                                                {
                                                    item.content_info.brief?
                                                        <div>
                                                            { item.content_info.brief }
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    <div className="newFirstImg">
                                        <img src={ item.base_info&&item.base_info.pressPic?item.base_info.pressPic:'' } alt="" style={{ width:'100%' }}/>
                                    </div>
                                </Link>
                                :
                                <div
                                  className="webSee newList"
                                   onClick={() => {
                                      history.push('/newdetail/' + item.com_base_id + getStore('pageSearch'));
                                    }}
                                >
                                  <div>
                                      {
                                          item.content_info&&item.content_info.postTime?
                                              <div style={{ width:85, height:85, backgroundColor:'rgba(222,222,222,1)', marginRight:20  }}>

                                                  <div style={{  margin:'7px 14px',fontSize:16,color:'#333333', fontWeight:400 }}>
                                                      <div style={{ fontSize:30,color:'#C90000' }}>
                                                          { item.content_info.postTime.split('T')[0].split('-')[2]}
                                                      </div>
                                                      <div>
                                                          { item.content_info.postTime.split('T')[0].split('-')[1]}
                                                      </div>
                                                      <div>
                                                          { item.content_info.postTime.split('T')[0].split('-')[0]}
                                                      </div>
                                                  </div>



                                              </div>
                                              :
                                              null
                                      }
                                  </div>

                                  <div style={{ flex:1 }}>
                                      <div style={{ fontSize:16, fontWeight:600, color:'#333333' }}>
                                          { item.content_name }
                                      </div>

                                      <div style={{ display:'flex',margin:'6px 0' }}>
                                          <div>
                                              {
                                                  item.content_info&&item.content_info.poster?
                                                      <span style={{ marginRight:40 }}>{newsPaper.content_info&&newsPaper.content_info.poster?newsPaper.content_info.poster:'发布者'}: { item.content_info.poster}</span>
                                                      :
                                                      null
                                              }
                                          </div>

                                          <div>
                                              {
                                                  item.content_info&&item.content_info.looks?
                                                      <span>{newsPaper.content_info&&newsPaper.content_info.looks?newsPaper.content_info.looks:'浏览次数'}: { item.content_info.looks}</span>
                                                      :
                                                      null
                                              }
                                          </div>
                                      </div>

                                      <div>
                                          {
                                              item.content_info&&item.content_info.brief?
                                                  <div className="newFontLine">
                                                      { item.content_info.brief.length>250?item.content_info.brief.substring(0,250) + "...":item.content_info.brief}
                                                  </div>
                                                  :
                                                  null
                                          }
                                      </div>
                                  </div>
                                </div>
                            :
                            null
                        :
                        null
                ))
            }

          {
            newsList.map((item, index) => ( index>=(nowPage-1)*3&&index<nowPage*3 &&
              item.content_info ?
                <div className="mobileSee" style={{ display:'flex', alignItems:'center' ,paddingBottom:16, borderBottom:'2px solid #DEDEDE', cursor:'pointer' }}
                     onClick={() => {
                       history.push('/newdetail/' + item.com_base_id + getStore('pageSearch'));
                     }}
                >
                  <div style={{ flex:'1' }}>
                    <div>

                      <div style={{ margin:'8px 0', color:'#333333', display: 'flex' }}>
                        <div style={{  flex: 1 }}>
                          <div className="newFontLine" style={{ fontWeight:600, fontSize:20, lineHeight:1.2 }}>{item.content_name}</div>

                          {index % 2 === 0 && item.base_info.pressPic ?
                            <div style={{ display:'flex', alignItems:'center', fontSize: 14, marginTop: 12 }}>
                              <img src="/img/timer.png" alt="" style={{ width:12, marginRight:5 }}/>
                              <div>发布时间：</div>
                              <div>{item.content_info.postTime.split('T')[0]}</div>
                            </div>
                            :
                            null
                          }
                        </div>
                        {index % 2 === 0 && item.base_info.pressPic ?
                          <img src={item.base_info.pressPic} alt="" style={{ maxWidth:'35%', height: 80, marginLeft: 12 }}/>
                          :
                          null
                        }
                      </div>
                      {
                        index % 2 === 1 &&item.content_info.brief?
                          <div>
                            <div style={{ letterSpacing:'1px' }}>{ item.content_info.brief }</div>
                            <div style={{ display:'flex', alignItems:'center', fontSize: 14, marginTop: 12 }}>
                              <img src="/img/timer.png" alt="" style={{ width:12, marginRight:5 }}/>
                              <div>发布时间：</div>
                              <div>{item.content_info.postTime.split('T')[0]}</div>
                            </div>
                          </div>
                        : null
                      }
                    </div>

                  </div>

                </div>
                : null
            ))}

            {/* 分页 */}

            <div style={{ display:'flex', justifyContent:'center', alignItems:'center',margin:'22px 0 49px 0' }}>
                <div className="pageBtn" onClick={() => {
        setNowPage(1)}}>
                  {newsPaper.content_info&&newsPaper.content_info.firstPage?newsPaper.content_info.firstPage:'首页'}
                </div>
                <div className="pageBtn" onClick={() => {
        setNowPage(nowPage>1?nowPage-1:1)}}>
                  {newsPaper.content_info&&newsPaper.content_info.last?newsPaper.content_info.last:'上一页'}
                </div>

                <div style={{ color:'#C90000' }}>
                    { nowPage }
                </div>
                /{ newsNumberPage }
                <div className="pageBtn" onClick={() => {
        setNowPage(nowPage<newsNumberPage?nowPage+1:newsNumberPage)}}>
                  {newsPaper.content_info&&newsPaper.content_info.next?newsPaper.content_info.next:'上一页'}
                </div>

                <div className="pageBtn" onClick={() => {
        setNowPage(newsNumberPage)}}>
                  {newsPaper.content_info&&newsPaper.content_info.endPage?newsPaper.content_info.endPage:'上一页'}
                </div>
            </div>
        </div>


    </div>
    );
}

export default News;