import React, {useState} from 'react';
import { useMiracleObserver, useMiracleObserverHot, createNotifyStation, SubscribeMatchType, getStore } from 'miracle-observer';
import './App.css';

function Cooperation(props) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [coopBase, setCoopBase] = useState(() => {
    return getStore('coopBase')
  });

  const [posionList, setPosionList] = useState([]);

  useMiracleObserver(
    'getCoopBase.getPosionList',
    (key, value, store) => {
      if(key === 'getCoopBase'){
        setTimeout(() => {
          window.staticpage.upload(getStore('pageUrl'), value.content_info.searchTitle, value.content_info.searchDesc, value.content_info.keywords);
        },500);
        setCoopBase(value);
      }
      if(key === 'getPosionList'){
        setPosionList(value);
      }
    },
    SubscribeMatchType.ContainsNotify,
  );

  return (
    <div>
      <div style={{ overflow: 'hidden', position: 'relative', lineHeight: 0, pointerEvents: 'none' }}>
        <div className="swap" style={{ left: photoIndex === 0 ? 0 : `-${photoIndex}00%`, zIndex: -1 }}>
          {
            coopBase['base_info'].photoList?
              coopBase['base_info'].photoList.map((item,index) => (
                <img key={`coopBasePhoto${item}`} src={item} alt="" style={{ opacity: photoIndex === index ? 1 : 0, transitionDuration: photoIndex === index ?'0.5s' : '1.5s', width: '100%' }} />
              ))
              :
              null
          }
        </div>

        <div className="swapButton" style={{ pointerEvents: 'all', display: coopBase['base_info'].photoList && coopBase['base_info'].photoList.length > 1 ? '' : 'none' }}>
          {
            coopBase['base_info'].photoList?
              coopBase['base_info'].photoList.map((item,index) => (
                <div
                  onClick={() => {
                    setPhotoIndex(index);
                  }}
                  style={{ background: photoIndex === index ? '#C90000' : '#FFFFFF' }}
                />
              ))
              :
              null
          }
        </div>
      </div>

      <div className="content">

        <div className="webSee aboutTitle">
          <img src="/titleLeft.png" style={{ height: 20 }} alt="" />
          <div><span style={{ color: coopBase['content_info'].leftColor1 }}>{coopBase['content_info'].leftFont1}</span><span style={{ marginLeft: 2, color: coopBase['content_info'].rightColor1 }}>{coopBase['content_info'].rightFont1}</span></div>
          <img src="/titleRight.png" style={{ height: 20 }} alt="" />
        </div>
        <div style={{ display: 'flex' }}>
          <img src={coopBase['base_info'].picture} alt="" style={{ width: '40%', marginRight: 32 }} />
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {
              posionList.map((item) => (
                  <div style={{ border:'1px solid #D8D8D8', padding: 12 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 18 }}>
                      <div style={{ color: '#666' }}>{ item.content_name }</div>
                      <div style={{ color: '#C90000' }}>{ item.content_info? item.content_info.price?item.content_info.price:'':'' }</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 14, marginTop: 8 }}>
                      <pre style={{ color: '#999999',margin: 0, whiteSpace: 'pre-wrap' }}>
                        { item.content_info? item.content_info.ask?item.content_info.ask:'':'' }
                      </pre>
                      <div style={{ color: '#999999'}}>{ item.content_info? item.content_info.addr?item.content_info.addr:'':'' }</div>
                    </div>
                  </div>
              ))
            }

          </div>
        </div>
        <div className="webSee aboutTitle">
          <img src="/titleLeft.png" style={{ height: 20 }} alt="" />
          <div><span style={{ color: coopBase['content_info'].leftColor2 }}>{coopBase['content_info'].leftFont2}</span><span style={{ marginLeft: 2, color: coopBase['content_info'].rightColor2 }}>{coopBase['content_info'].rightFont2}</span></div>
          <img src="/titleRight.png" style={{ height: 20 }} alt="" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="coopContent" style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: coopBase['content_rtf'] }} />
          <img src={coopBase['base_info'].picture2} alt="" style={{ width: '40%', marginLeft: 32 }} />
        </div>

      </div>
    </div>
  );
}

export default Cooperation;