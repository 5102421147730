/**
 * Created by 97817 on 2020/4/27.
 */
import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {SubscribeMatchType, useMiracleObserver, getStore} from 'miracle-observer';
import { Link } from "react-router-dom";
import './NewDetail.css';
import './App.css';
import appServices from './services';


function NewDetail(props) {
    let history = useHistory();
    let { id } = useParams();


    useEffect(() => {
        // init call
        console.log('useEffect:' + id);
        console.log(history.action);
        appServices.getNewsDetail(id)
        appServices.getCorrNewslist(id)
    }, [id, history.location.search]);

    //新闻详情
    const [newDetail, setNewDetail] = useState([]);

    //新闻抬头图片
    const [newsPic, setNewsPic] = useState([]);

    //相关新闻
    const [corrnewsList, setCorrNewsList] = useState([]);

    const [newsPaper, setNewsPaper] = useState({});


    //观察者
    useMiracleObserver(
        'getNewDetail.getNewsPic.getCorrNewslist.getNewsPaper',
        (key, value, store) => {
            if(key === 'getNewDetail'){
              setTimeout(() => {
                window.staticpage.upload(getStore('pageUrl'), value.content_name, value.content_info.brief, value.content_info.keywords);
              },500);
              setNewDetail(value);
            }
            if(key === 'getNewsPic'){
                setNewsPic(value);
            }
            if(key === 'getCorrNewslist'){
                setCorrNewsList(value);
            }
            if(key === 'getNewsPaper'){
                setNewsPaper(value);
            }
        },
        SubscribeMatchType.ContainsNotify,
    );

    //getTimes = (time)  => {
    //    let newTime = time.split('T')[0]
    //    let newTimelist = newTime.split('-')
    //    return newTimelist
    //}


    return (
        <div className="App">
            <div className="webSee">
                {
                    newsPic.base_info&&newsPic.base_info.photoList&&newsPic.base_info.photoList.length>0?
                        <img src={ newsPic.base_info.photoList[0] } alt="" style={{  width:'100%' }}/>
                        :
                        null
                }
            </div>
            <div className="webSee content">
                <div style={{ position: 'relative', margin: '24px 0', height: 56 }}>
                    <div className="navBorder">
                        <img src="/pageNavHome.png" alt="" style={{ width: 18, height: 18, padding: '16px',cursor:'pointer' }} onClick={() => history.push('/')}/>
                        <img src="/pageNavRight.png" alt="" style={{ width: 5, height: 6 }} />
                        <div style={{ padding: 16, cursor:'pointer' }} onClick={() => history.push('/news'+ getStore('pageSearch'))}>{newsPaper.content_info&&newsPaper.content_info.leftFont1&&newsPaper.content_info.rightFont1?newsPaper.content_info.leftFont1+newsPaper.content_info.rightFont1:'新闻中心'}</div>
                        <img src="/pageNavRight.png" alt="" style={{ width: 5, height: 6, marginRight: 16 }} />
                        <div className="navSelect">{newsPaper.content_info&&newsPaper.content_info.newsDet?newsPaper.content_info.newsDet:'新闻详情'}</div>
                        <img className="navSelectImg" src="/pageNav.png" alt="" />
                    </div>
                </div>
            </div>
            <div className="webSee" style={{ width: '100%', height: 2, background: '#D8D8D8' }} />
            <div className="content">
                <div className="webSee toTop">

                    <img
                      onClick={() => {
                          document.getElementById('root').scrollTop = 0;
                        }}
                      src="/img/toTop.png"
                      alt=""
                      style={{ width:50 }}
                    />
                </div>

                <div style={{ display:'flex' }}>
                    {/* 日期 */}
                    <div>
                        {
                            newDetail.content_info&&newDetail.content_info.postTime?
                                <div className="webSee" style={{ width:110, height:110, backgroundColor:'rgba(222,222,222,1)', marginRight:25,  marginTop:26  }}>

                                    <div style={{  margin:'7px 14px',fontSize:16,color:'#333333', fontWeight:400 }}>
                                        <div style={{ fontSize:30,color:'#C90000' }}>
                                            { newDetail.content_info.postTime.split('T')[0].split('-')[2]}
                                        </div>
                                        <div>
                                            { newDetail.content_info.postTime.split('T')[0].split('-')[1]}
                                        </div>
                                        <div>
                                            { newDetail.content_info.postTime.split('T')[0].split('-')[0]}
                                        </div>
                                    </div>



                                </div>
                                :
                                null
                        }
                    </div>


                    {/*  新闻详情 */}
                    <div style={{ flex:1 }} >
                        <div className="nameNav">
                            { newDetail.content_name }
                        </div>
                        <div className="posterNav">
                            <div>
                                {
                                    newDetail.content_info&&newDetail.content_info.poster?
                                        <span style={{ marginRight:40 }}>{newsPaper.content_info&&newsPaper.content_info.poster?newsPaper.content_info.poster:'发布者'}: { newDetail.content_info.poster}</span>
                                        :
                                        null
                                }
                            </div>

                            <div>
                                {
                                    newDetail.content_info&&newDetail.content_info.looks?
                                        <span>{newsPaper.content_info&&newsPaper.content_info.looks?newsPaper.content_info.looks:'浏览次数'}: { newDetail.content_info.looks}</span>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className="limitInnerHTML" style={{ fontSize: 14 }}>
                            <div dangerouslySetInnerHTML={{ __html: newDetail.content_rtf }} />
                        </div>
                    </div>

                    <div className="webSee"  style={{ borderRight:'2px solid #D8D8D8', paddingLeft:25 }}>

                    </div>
                    {/* 相关新闻 */}
                    <div className="webSee" style={{ paddingLeft:15, width:'30%' }}>
                        <div>
                            <div style={{ marginRight:110 }}>
                                <div style={{ padding:'26px 0 7px 0', fontSize:18 }}>

                                    {newsPaper.content_info&&newsPaper.content_info.aboutNews?newsPaper.content_info.aboutNews:'相关新闻'}
                                </div>

                                {
                                    corrnewsList.map((item) => (

                                        <div>

                                            <div style={{ paddingTop:15 }}>
                                                <div style={{  fontSize:14 }}>
                                                    {
                                                        item.content_info&&item.content_info.postTime?
                                                            <div style={{ }}>
                                                                {item.content_info.postTime.split('T')[0]}
                                                            </div>
                                                            :
                                                            null

                                                    }
                                                </div>
                                                <div style={{ color:'#333333', fontSize:14, fontWeight:600 }}>
                                                    {
                                                        item.content_name?
                                                            <div style={{ }}>
                                                                {item.content_name}
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                {
                                                    item.content_info&&item.content_info.brief?
                                                        <div className="newRightLine">
                                                            { item.content_info.brief.length>200?item.content_info.brief.substring(0,200) + "...":item.content_info.brief}
                                                        </div>
                                                        :
                                                        null
                                                }

                                                <Link
                                                  to={'/newdetail/' + item.com_base_id + getStore('pageSearch')}
                                                  style={{ textDecoration: 'none', color:'#C90000', display:'flex', alignItems:'center', marginTop:5, paddingBottom:16, borderBottom:'1px solid #d8d8d8',cursor:'pointer' }}
                                                     onClick={() => {
                                                        history.push('/newdetail/' + item.com_base_id + getStore('pageSearch'));
                                                      }}
                                                >
                                                    {newsPaper.content_info&&newsPaper.content_info.detinfo?newsPaper.content_info.detinfo:'详细信息'}
                                                    <img src="/img/right.png" alt="" style={{ width:6, marginLeft:5 }}/>
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                }



                            </div>

                        </div>


                    </div>

                </div>
            </div>


        </div>
    );
}

export default NewDetail;