import React, {useState} from 'react';
import {SubscribeMatchType, useMiracleObserver, getStore, useMiracleStore} from 'miracle-observer';
import { useHistory } from 'react-router-dom';

function ComOne(props) {
  let history = useHistory();

  const [count, setCount, initCount] = useMiracleStore(
    (store) => {
      return store['count'];
      },
    (v, store) => {
      store['count'] = v;
      return v;
    });

  const [aboutPaper, setAboutPaper] = useState(() => {
    return getStore('about1');
  });

  useMiracleObserver(
    'updateAboutContent',
    (key, value, store) => {
      setAboutPaper(value.content_rtf);
    },
    SubscribeMatchType.ContainsNotify,
  );

  return (
    <div>
      <div onClick={() => {
        history.push('/com_one?lang=en')
      }}>
        切换语言
      </div>
      <div className="App" onClick={() => {
        setCount(count + 1);
        history.push('/com_two/' + count);
      }}>
        <div dangerouslySetInnerHTML={{ __html: aboutPaper }} />
      </div>
    </div>
  );
}

export default ComOne;