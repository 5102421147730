import { initStore } from 'miracle-observer';

initStore(window.location.href.indexOf('lang=en') >= 0 ? 5 : 4, 'i18n_id');
initStore(window.location.href.indexOf('lang=en') >= 0 ? { name: '英语', image: 'http://assets.amzport.com/09a100e2-4d68-443b-b5e8-b175909572bf' } : { name: '中文', image: 'http://assets.amzport.com/f08e12a7-f381-4678-9af1-7ea00e476051' }, 'i18n_info');
initStore(0, 'i18n_oldId');
initStore('', 'pageUrl');
initStore('', 'pageSearch');

//首页
//轮播图
initStore({ content_info: {}, base_info: {} }, 'homeBase');
initStore({ content_info: {}, base_info: {} }, 'homePaper');

//证书
initStore([], 'certlist');
//合作伙伴
initStore([], 'parlist');

//关于我们
initStore({ content_info: {}, base_info: {} }, 'aboutPaper');
//产品展示
initStore({ content_info: {}, base_info: {} }, 'proPaper');
initStore([], 'proClass');
initStore('food_and_beverage_cans', 'nowProClass');
initStore('食品饮料罐', 'nowProClassCont');
initStore([], 'proClassList');
initStore([], 'showProList');

//新闻中心
initStore({ content_info: {}, base_info: {} }, 'newsPaper');
initStore({ content_info: {}, base_info: {} }, 'coopBase');
initStore({ content_info: {}, base_info: {} }, 'contactBase');
initStore({}, 'newsPic');
initStore([], 'newList');
initStore({}, 'newDetail');
initStore(1, 'newsNumberPage');//新闻页码总数
initStore(1, 'nowPage');//当前页码
//相关新闻
initStore([], 'corrNewslist');

//招聘
initStore([], 'posionList');
