import React, {useState, useEffect} from 'react';
import {Switch, Route, Link, useLocation, useHistory, useParams } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Product from "./Product";
import News from "./News";
import NewDetail from "./NewDetail";
import Cooperation from "./Cooperation";
import Contact from "./Contact";
import BottomTab from "./BottomTab";
import ComOne from './example/ComOne';
import ComTwo from './example/ComTwo';
import './App.css';
import { useMiracleObserver, useMiracleObserverHot, createNotifyStation, SubscribeMatchType } from 'miracle-observer';
import services from './example/services';
import appServices from './services';
import {getStore, initStore} from 'miracle-observer';

function usePageViews(setLocationUrl, locationUrl) {
  let history = useHistory();

  let location = useLocation();
  if (setLocationUrl) {
    setTimeout(() => {
      setLocationUrl(location.pathname);
    },300);
  }
  React.useEffect(() => {
    console.log('useEffect',location.pathname);
    if (getStore('i18n_id') !== getStore('i18n_oldId')) {
      initStore(getStore('i18n_id'), 'i18n_oldId');
      appServices.getAllStyle();
    }
    initStore(location.pathname.substring(1) + location.search, 'pageUrl');
    initStore(location.search, 'pageSearch');

    if (location.pathname !== '/' && location.pathname !== locationUrl) {
      document.getElementById('root').scrollTop = 0;
    }
    if (location.pathname === '/com_one') {
      services.fetchAboutContent()
    } else if (location.pathname.startsWith('/com_two')) {
      services.fetchDict()
    } else if(location.pathname === '/about'){
      // appServices.getAboutPaper();
    }
    else if(location.pathname === '/'){
      //首页
      appServices.getCertlist()
      appServices.getParlist()
      // appServices.getHomePaper();
      appServices.getNewsList()
      appServices.getAllProClass()
      appServices.getShowProList()


    }
    else if(location.pathname === '/cooperation'){
      // appServices.getCoopBase();
      appServices.getPosionList();
    }
    else if(location.pathname === '/contact'){
      // appServices.getContactBase();
    }
    else if(location.pathname === '/product'){
      // appServices.getProPaper()
      appServices.getAllProClass()
      appServices.getProClassList()


    }else if(location.pathname === '/news'){
      //initStore(0, 'newsNumber');
      appServices.initNewsNumber()//初始化页码

      // appServices.getNewsPaper()
      appServices.getNewsPic()
      appServices.getNewsList()
    }
    else if(location.pathname.startsWith('/newdetail')){
      console.log(location);
      //appServices.getNewsDetail(71)
      appServices.getNewsPic()
      appServices.getNewsList()
      appServices.getNewsPaper()
    }
    else {
      console.log('没有匹配')
      console.log(location.pathname);
    }
  }, [location]);
}


const axios = require('axios');
const station = createNotifyStation((k, v, store) => {
  // example
  store[k] = v;
}, SubscribeMatchType.ExactMatch | SubscribeMatchType.ContainsNotify);

export default function App() {
  const [locationUrl, setLocationUrl] = useState('/');
  let history = useHistory();
  usePageViews(setLocationUrl, locationUrl);
  const [navOpen, setNavOpen] = useState(false);
  const [navList, setNavList] = useState([]);
  const [languageList, setLanguageList] = useState(() => {
    axios.get(`/api/web_in`)
      .then((response) => {
        setLanguageList(response.data)
      }).catch((error) => {
      console.log(error.toJSON());
    });
    return [];
  });

  useMiracleObserver(
    'setNavList',
    (key, value, store) => {
      if(key === 'setNavList'){
        setNavList(value);
      }
    },
    SubscribeMatchType.ContainsNotify,
  );

  return (
    <div className="navTop">
      <nav className="navStyle">
        <div>
          <img className="navImg" alt="" src="/logo.png" />
        </div>
        <div style={{ marginLeft: 8 }}>
          <div className="navTitle">宁波飞龙印铁容器有限公司</div>
          <div className="navEnglish">Ningbo Feilong Tinplate Printing Vessel Co.,Ltd.</div>
        </div>
        <div
          onClick={() => {
            setNavOpen(false);
          }}
          className="navWeb"
          style={navOpen ? { right: 0 } : { }}
        >
          {navList.map(item => ( item.link === '/cooperation' || item.link === '/contact' ?
            <div>
              <Link className="webSee navLink" style={locationUrl === item.link ?{ color: '#C90000', borderColor: '#C90000' } : { color: '#333' }} to={item.link + getStore('pageSearch')}>{item.name}</Link>
              {item.link === '/cooperation' ?
                <Link
                  to={`/` + getStore('pageSearch')}
                  className="mobileSee navLink"
                  style={locationUrl === '/contact' ? {color: '#C90000', borderColor: '#C90000'} : {color: '#333'}}
                  onClick={() => {
                    document.getElementById('root').scrollTop = (document.getElementById('root').scrollHeight - document.getElementById('root').offsetHeight - 1500)
                  }}
                >
                  {item.name}
                </Link>
                :
                <Link
                  to={`/` + getStore('pageSearch')}
                  className="mobileSee navLink"
                  style={locationUrl === '/contact' ?{ color: '#C90000', borderColor: '#C90000' } : { color: '#333' }}
                  onClick={() => { document.getElementById('root').scrollTop = (document.getElementById('root').scrollHeight - document.getElementById('root').offsetHeight)}}
                >
                  {item.name}
                </Link>
              }
            </div>
            :
            <div>
              <Link className="navLink" style={locationUrl === item.link ?{ color: '#C90000', borderColor: '#C90000' } : { color: '#333' }} to={item.link + getStore('pageSearch')}>{item.name}</Link>
            </div>
          ))}
          <div className="mobileSee" style={{ margin: '12px 0', width: '100%', height: 1, background: '#999999', padding: 0 }} />

          {languageList.map(item => (
            <div
              className="mobileSee"
              style={{ color: getStore('i18n_info').name === item.name ? '#C90000' : '#333' }}
              onClick={() => {
                initStore(item.i18n_id, 'i18n_id');
                initStore(item, 'i18n_info');
                history.push(locationUrl + '?lang=en');
              }}
            >
              <img src={item.image} alt="" style={{ height: 12, marginRight: 12 }} />
              <span>{getStore('i18n_info').name === '中文' ? item.name : item.locale}</span>
            </div>
          ))}

          <div className="navLanguage">
            <img src={getStore('i18n_info').image} alt="" style={{  height: 12, marginRight: 6 }} />
            <span>{getStore('i18n_info').name}</span>

            <div className="navTabLanguage">
              {languageList.map(item => (
                <div
                  onClick={() => {
                    initStore(item.i18n_id, 'i18n_id');
                    initStore(item, 'i18n_info');
                    history.push(`${locationUrl}?lang=${item.locale}`);
                  }}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', lineHeight: 1  }}
                >
                  <img src={item.image} alt="" style={{ height: 12, marginRight: 12 }} />
                  <span>{getStore('i18n_info').name === '中文' ? item.name : item.locale}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        {navOpen ?
          <img
            src="./navClose.png"
            alt=""
            className="navIcon"
            onClick={() => {
              setNavOpen(false);
            }}
          />
          :
          <img
            src="/navTab.png"
            alt=""
            className="navIcon"
            onClick={() => {
              setNavOpen(true);
            }}
          />
        }
      </nav>

        {/* A <Switch> looks through its children <Route>s and
         renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/product">
          <Product />
        </Route>
        <Route path="/news">
          <News />
        </Route>
        <Route path="/newdetail/:id">
          <NewDetail />
        </Route>
        <Route path="/cooperation">
          <Cooperation />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/com_one">
          <ComOne />
        </Route>
        <Route path="/com_two/:id">
          <ComTwo />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>

      <BottomTab />
    </div>
  );
}