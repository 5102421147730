import React, {useState,useEffect} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {SubscribeMatchType, useMiracleObserver, getStore, useMiracleStore} from 'miracle-observer';
import './App.css';
import './Product.css';
import appServices from './services';

function Product(props) {
    let history = useHistory();

    const [proPaper, setProPaper] = useState(() => {
      return getStore('proPaper')
    });

    const [allProClass, setAllProClass] = useState([]);

    //当前类
    const [nowProClass, setNowProClass, initNowProClass] = useMiracleStore(

        (store) => {
            return store['nowProClass'];
        },
        (v, store) => {
            console.log('nowProClass--Value',v)
            store['nowProClass'] = v;
            appServices.getProClassList();
            return v;
        });

        const [nowProClassCont, setNowProClassCont, initNowProClassCont] = useMiracleStore(

                (store) => {
                    return store['nowProClassCont'];
                },
                (v, store) => {
                    store['nowProClassCont'] = v;
                    return v;
                });

    //某类产品
    const [proClassList, setProClassList] = useState([]);

    //是否查看详情
    const [isProDet, setIsProDet] = useState(false);
    //详情
    const [proDet, setProDet] = useState({});



  //手机端下拉
  const [isDown, setIsDown] = useState(false);
  const [target, setTarget] = useState(false);

  //轮播图
  const [photoIndex, setPhotoIndex] = useState(0);


    useMiracleObserver(
        'getProPaper.getAllProClass.getProClassList.nowProClassCont.nowProClass',
        (key, value, store) => {

            if(key === 'getProPaper'){
              setTimeout(() => {
                window.staticpage.upload(getStore('pageUrl'), value.content_info.searchTitle, value.content_info.searchDesc, value.content_info.keywords);
              },500);
                setProPaper(value);
            }
            if(key === 'getAllProClass'){
                setAllProClass(value);
            }
            if(key === 'getProClassList'){
                setProClassList(value);
            }
            if(key === 'nowProClassCont'){
              setNowProClassCont(value);
            }
            if(key === 'nowProClass'){
              setNowProClass(value);
            }

        },
        SubscribeMatchType.ContainsNotify,
    );

    return (
        <div className="App">
            <div className="webSee">
                {
                    proPaper.base_info&&proPaper.base_info.photoList&&proPaper.base_info.photoList.length>0?
                      <div style={{ position:'relative' }}>
                        <img src={ proPaper.base_info.photoList[0] } alt="" style={{  width:'100%' }}/>
                        <div style={{ position:'absolute', top:'30%', right:100, textAlign:'center' }}>
                          <div style={{ fontSize:50, color:'#fff', marginBottom:20 }}>
                            {proPaper.content_info&&proPaper.content_info.leftFont1?proPaper.content_info.leftFont1:'今天的质量 明天的效益'}
                          </div>
                          <div style={{ fontSize:20, color:'#fff' }}>
                            {proPaper.content_info&&proPaper.content_info.rightFont1?proPaper.content_info.rightFont1:"Today's quality makes tomorrow's benefit"}
                          </div>

                        </div>
                      </div>

                        :
                        null
                }
            </div>

            <div className="content">
              {
                isProDet?
                  <div className="webSee">
                    <div style={{ position: 'relative', margin: '24px 0', height: 56 }}>
                      <div className="navBorder">
                        <img src="/pageNavHome.png" alt="" style={{ width: 18, height: 18, padding: '16px',cursor:'pointer' }} onClick={() => history.push('/'+ getStore('pageSearch'))}/>
                        <img src="/pageNavRight.png" alt="" style={{ width: 5, height: 6 }} />
                        <div style={{ padding: 16, cursor:'pointer' }} onClick={() => {
                                    setIsProDet(false)}
                                  }>{ nowProClassCont }</div>
                        <img src="/pageNavRight.png" alt="" style={{ width: 5, height: 6, marginRight: 16 }} />
                        <div className="navSelect">{proPaper.content_info&&proPaper.content_info.proDet?proPaper.content_info.proDet:'产品详情'}</div>
                        <img className="navSelectImg" src="/pageNav.png" alt="" />
                      </div>
                    </div>
                  </div>
                  :
                  null
              }



                <div className="webSee" style={{ display:'flex', margin:'54px 0 65px 0',fontSize:14, alignItems: 'flex-start' }}>


                    <div className="productLeft">
                        {
                            allProClass.map((item) => (
                                <div
                                  style={{ width:'80%',padding:'10px 35px 10px 0',backgroundColor:nowProClass===item.base_tag?'#fff':'', color:nowProClass===item.base_tag?'#333333':'#fff', margin:'10px 0', borderTopLeftRadius:20, borderBottomLeftRadius:20, cursor:'pointer' }}
                                  onClick={() => {
                                    setNowProClass(item.base_tag);setIsProDet(false);setNowProClassCont(item.content_name)}
                                  }
                                >

                                    { item.content_name }
                                </div>
                            ))
                        }

                    </div>

                    {
                        isProDet?
                            <div style={{ flex:'1' }} >
                                <div style={{ paddingLeft:18, backgroundColor:'rgba(50, 50, 50, 0.9)' }}>
                                    <div  style={{ padding:'14px 0 ', color:'#fff',fontSize:18, fontWeight:600 }}>
                                        { proDet.content_name }
                                    </div>


                                </div>
                                <div  style={{ paddingLeft:18, backgroundColor:'#D8D8D8', fontSize: 16 }}>
                                    <div>
                                        {
                                            proDet.content_info&&proDet.content_info.kind?
                                                <div style={{ padding:'14px 0 ', color:'#333333' }}>
                                                    { proDet.content_info.kind }
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                  <div style={{ width: '100%', height: 2, background: '#DEDEDE' }} />
                                    <div>
                                        {
                                            proDet.content_info&&proDet.content_info.mainClient?
                                                <div style={{ padding:'14px 0 ', color:'#333333' }}>
                                                    { proDet.content_info.mainClient }
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>


                                <div style={{ paddingTop:'20px' }}>
                                    {
                                        proDet.base_info&&proDet.base_info.photoList?

                                            proDet.base_info.photoList.length===1?
                                                <img src={ proDet.base_info.photoList[0] }alt=""/>
                                                :
                                            proDet.base_info.photoList.length===2?
                                                <div>
                                                    {
                                                        proDet.base_info.photoList.map((gitem, index) =>(
                                                            <img src={ proDet.base_info.photoList[index] } alt="" style={{ width:'calc(50% - 10px)', marginRight:index===0?'20px':'' }}/>
                                                        ))
                                                    }

                                                </div>
                                                :

                                                <div style={{ display:'flex' }}>

                                                    <div style={{ flex:1 }}>
                                                        <img src={ proDet.base_info.photoList[0] } alt="" style={{ width:'100%' }}/>
                                                    </div>
                                                    <div style={{ width:'30%', marginLeft:30 }}>
                                                        <div>
                                                            <img src={ proDet.base_info.photoList[1] } alt="" style={{ width:'100%' }}/>
                                                        </div>
                                                        <div style={{ marginTop:30 }}>
                                                            <img src={ proDet.base_info.photoList[2] } alt="" style={{ width:'100%' }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                            null


                                    }
                                </div>

                                <div style={{ textAlign:'right', }}>

                                        <span style={{  display:'inline-block',marginTop:'50px', padding:'13px 30px' ,backgroundColor:'#C90000', color:'#FFFFFF', fontSize:18 }}>

                                          {proPaper.content_info&&proPaper.content_info.toRelation?proPaper.content_info.toRelation:'购买请联系'}
                                        </span>


                                </div>

                            </div>
                            :
                            <div className="productList">
                                {
                                    proClassList.map((item, index) => (
                                        <div
                                          className="productItem"
                                          onClick={() => {
                                            setIsProDet(!isProDet);
                                            setProDet(item)
                                          }}
                                        >

                                            {
                                                item.base_info&&item.base_info.photoList?
                                                    <div className="productBlock">
                                                        <img src={ item.base_info.photoList[0] } alt="" style={{ height:150 }}/>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div style={{ padding:'9px 0 22px 0', textAlign:'center', fontSize:18 }} >
                                                { item.content_name }
                                            </div>



                                        </div>
                                    ))
                                }

                            </div>
                    }

                </div>
              


            </div>

          <div className="mobileSee">

            <div>
              {
                allProClass.map((item) => (
                  nowProClass===item.base_tag?
                    <div style={{ position:'fixed', width:'100%', backgroundColor:'#fff', top:74 }}>

                      <div style={{ display:'flex', alignItems:'center', padding:'15px 20px', borderBottom:'1px solid #DEDEDE' }}>
                        <div style={{ flex:1, fontSize:20, color:'#333', fontWeight:600 }}>
                          { item.content_name }
                        </div>

                        <img
                          src="/img/down.png"
                          alt=""
                          style={{ height:10, transform: isDown ? 'rotate(180deg)' : 'rotate(0deg)', transitionDuration: '1s' }}
                          onClick={() => {
                            setIsDown(!isDown);
                            setTarget(true)
                          }}
                        />
                      </div>
                      {isDown?
                        <div
                          onClick={() => {
                            setIsDown(!isDown);
                             setTarget(true)
                          }}
                          style={{ position: 'fixed', background: 'rgba(0,0,0,0.3)', width: '100%', height: '100%', opacity:isDown?1:0, transitionDuration: '1s' }}
                        />
                        :
                        null
                      }

                      <div style={{ overflow:'hidden' }}>
                        <div className={ target?isDown?'showMore':'hiddMore':'' }  style={target?{   padding:'0px 67px',position:'fixed', backgroundColor:'#fff', width:'calc(100% - 134px )' }:{display:'none'}}>
                          {
                            allProClass.map((gitem) => (
                              allProClass!==gitem.base_tag?
                                <div
                                  style={{ padding:'13px 0', borderBottom:'1px solid #DEDEDE', fontSize:18, cursor: 'pointer' }}
                                  onClick={() => {
                                    setNowProClass(gitem.base_tag);
                                    setIsProDet(false);
                                    setIsDown(!isDown);
                                     setTarget(true)
                                    document.getElementById('root').scrollTop = 0;}
                                 }
                                >
                                  { gitem.content_name }
                                </div>
                                :
                                null
                            ))
                          }
                        </div>
                      </div>


                    </div>

                    :
                    null
                ))
              }

              {
                isProDet?
                  <div style={{ marginTop:82}}>

                    <div style={{ fontSize:10, borderRadius:3, overflow:'hidden', margin:'36px 60px 32px 60px', }}>
                      <div style={{ paddingLeft:18, backgroundColor:'#323232' }}>
                        <div  style={{ padding:'10px 0 ', color:'#fff',fontSize:12, fontWeight:600 }}>
                          { proDet.content_name }
                        </div>


                      </div>
                      <div  style={{ paddingLeft:18, backgroundColor:'#D8D8D8' }}>
                        <div>
                          {
                            proDet.content_info&&proDet.content_info.kind?
                              <div style={{ padding:'10px 0 ', color:'#333333', borderBottom:'1px solid #DEDEDE' }}>
                                { proDet.content_info.kind }
                              </div>
                              :
                              null
                          }
                        </div>
                        <div>
                          {
                            proDet.content_info&&proDet.content_info.mainClient?
                              <div style={{ padding:'10px 0 ', color:'#333333' }}>
                                { proDet.content_info.mainClient }
                              </div>
                              :
                              null
                          }
                        </div>
                      </div>
                    </div>

                    <div style={{ overflow: 'hidden', position: 'relative', lineHeight: 0, pointerEvents: 'none', margin:'0 20px 30px 20px' }}>
                      <div className="swap" style={{ left: photoIndex === 0 ? 0 : `-${photoIndex}00%`, zIndex: -1 }}>
                        {
                          proDet.base_info&&proDet.base_info.photoList?
                            proDet.base_info.photoList.map((item,index) => (
                              <img key={`proDetPhoto${item}`} src={item} alt="" style={{ opacity: photoIndex === index ? 1 : 0, transitionDuration: photoIndex === index ?'0.5s' : '1.5s', width: '100%' }} />
                            ))
                            :
                            null
                        }
                      </div>


                    </div>
                    <div className="swapButton2" style={{ pointerEvents: 'all', display: proDet.base_info.photoList && proDet.base_info.photoList.length > 1 ? '' : 'none' }}>
                      {
                        proDet.base_info&&proDet.base_info.photoList?
                          proDet.base_info.photoList.map((item,index) => (
                            <div
                              onClick={() => {
                  setPhotoIndex(index);
                }}
                              style={{ background: photoIndex === index ? '#666666' : '#DEDEDE' }}
                            />
                          ))
                          :
                          null
                      }
                    </div>

                    <div style={{ margin:'60px 70px' }}>

                      <div style={{ fontSize:20, color:'#fff', backgroundColor:'#C90000', padding:'10px 0', textAlign:'center', borderRadius:3, }}>
                        {proPaper.content_info&&proPaper.content_info.toRelation?proPaper.content_info.toRelation:'购买请联系'}
                      </div>

                    </div>

                  </div>
                  :
                  <div style={{ padding:'30px  20px', display:'flex', flexWrap:'wrap', marginTop: 48 }}>

                    {
                      proClassList.map((item, index) => (
                        <div style={{ cursor: 'pointer',width:index===0? '100%':'calc(50% - 10px)',marginRight: index===0||index%2===0  ? '0px' : 20,backgroundColor:'#D8D8D8',marginBottom:15   }}
                             onClick={() => {
                                        setIsProDet(!isProDet);
                                        setProDet(item)
                                      }}
                        >

                          <div style={{ }}>
                            <div style={{ padding:index===0?'36px 80px':'32px 18px' }}>
                              {
                                item.base_info&&item.base_info.photoList?
                                  <div style={{ textAlign:'center'}}>
                                    <img src={ item.base_info.photoList[0] } alt="" style={{ width:'100%' }}/>
                                  </div>
                                  :
                                  null
                              }
                              <div style={{ marginTop:9, textAlign:'center', fontSize:18, color:'#333333' }} >
                                { item.content_name }
                              </div>
                            </div>

                          </div>




                        </div>
                      ))
                    }
                  </div>
              }

            </div>
          </div>
        </div>
    );
}

export default Product;