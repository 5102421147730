import React, {useState} from 'react';
import { useMiracleObserver, useMiracleObserverHot, createNotifyStation, SubscribeMatchType } from 'miracle-observer';
import './App.css';

function BottomTab() {
  const [bottomLeft, setBottomLeft] = useState({ content_info: {}, base_info: {} });
  const [bottomRight, setBottomRight] = useState({ content_info: {} });

    useMiracleObserver(
        'getBottomTab',
        (key, value, store) => {
          setBottomLeft(value[0]);
          setBottomRight(value[1]);
        },
        SubscribeMatchType.ContainsNotify,
    );

  return (
    <div id="contactBottom" className="bottomPaper">
      <div className="bottomContent">
        <div className="bottomBlock">
          <div className="bottomFont" style={{textAlign:'center' }}>{bottomLeft.content_info.leftFont1}</div>
          {bottomLeft.base_info.photoList && bottomLeft.base_info.photoList[0] ?
            <img src={bottomLeft.base_info.photoList[0]} alt="" style={{ width: 138 }} />
            :
            null
          }
        </div>
        <div className="bottomBlock">
          <div className="bottomTitle">{bottomLeft['content_name']}</div>
          <div className="bottomHtml" dangerouslySetInnerHTML={{ __html: bottomLeft['content_rtf'] }} />
        </div>
        <div>
          <div className="bottomTitle" >{bottomRight['content_name']}</div>
          <div className="bottomHtml" dangerouslySetInnerHTML={{ __html: bottomRight['content_rtf'] }} />
        </div>
      </div>
      <div style={{ background: '#fff', height: 1, width: '100%', marginBottom: 30, opacity: 0.7 }} />
      {bottomLeft.base_info.linkList && bottomLeft.base_info.linkList[0] ?
        <div style={{ fontSize: 12, opacity: 0.7 }}>
          {bottomLeft.content_info.leftFont2}：
          {bottomLeft.base_info.linkList.map(item => (
            <div style={{ display: 'inline-block', marginRight: 16, marginBottom: 12 }}>
              <a style={{ color: 'inherit', textDecoration: 'none' }} href={item.url} alt={item.name} target="_blank">{item.name}</a>
            </div>
          ))}
        </div>
        :
        null
      }

      <div style={{ fontSize: 12, opacity: 0.7 }}>
        {bottomLeft.content_info.rightFont1}
        <a href="http://beian.miit.gov.cn/" alt="" target="_blank" style={{ color: 'inherit', textDecoration: 'none', margin: '0 12px 0 6px' }}>浙ICP备20012268号-1 © Copyright©2000-2020 飞龙印铁 版权所有</a>
        <div style={{ display: 'inline-block' }}>
          {bottomLeft.content_info.rightFont2}： <a style={{ color: 'inherit', textDecoration: 'none' }} href="http://www.amzport.com" alt="奇佑网科" target="_blank">奇佑网科</a>
        </div>
        </div>
    </div>
  );
}

export default BottomTab;